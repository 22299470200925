<template>
  <div class=''>
    <router-view></router-view>
  </div>
</template>

<script>

import Cookies from "js-cookie";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  name: "across_second",
  data() {
//这里存放数据
    return {
      datalist:[],

    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    getDataList() {
      this.loadingShow = true

      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/staticecg/list"),
        method: "get",
        params: this.$http.adornParams({ uid: Cookies.get('PID') }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看datalist数据");
          console.log(data)
          this.datalist = data.staticEcgEntities;
          this.tableData = new Array(this.datalist.length)
          this.tableData = this.datalist.reverse()
          // // console.log(this.tableData)
          // var timelist = new Array(this.datalist.length)
          // for (let i = 0; i < this.datalist.length; i++) {
          //   this.$set(this.tableData[i], "conclusion", '') //添加结论数据
          //   this.$set(this.tableData[i], "checkStatus", '')//添加复核状态
          //   this.$set(this.tableData[i], "userDescribe", '')//添加症状描述
          //   timelist[i] = this.tableData[i].createTime.substring(0, 10)
          // }
          // this.latter = timelist[0]
          // this.former = timelist.pop()
          // this.geConclusionList(this.former, this.latter)
        } else {
          // this.getCaptcha()
          console.log("看看报错");
          this.$message.error(data.msg);
        }
      });

    },
    goECG(){
      console.log("看看data存储")
      console.log(this.tableData[0])
      this.$router.push({
        // name: 'HistorySingo2',
        name: 'UserSingo2Direct',
        query: {
          "uid": this.tableData[0].uid,
          "staticRecordId": this.tableData[0].sid,
          "staticRecordCreatetime": this.tableData[0].createTime,
          "staticRecordDuration": this.tableData[0].duration,
        }
      })
      // console.log("看看2023年1月8日");
      // console.log(Cookies.get('PID'));
      // this.crossPlatformStatus = true
      // let uid = Cookies.get('PID')
      // this.$router.push({ name: 'UserSingoTreat',
      //   query: {
      //     uid: uid,
      //   } })
    },
    goECGHolter(){
      console.log("看看2023年1月8日");
      console.log(Cookies.get('PID'));
      this.crossPlatformStatus = true
      let uid = Cookies.get('PID')

      this.$router.push({ name: 'UserSingoHolterTreat',
        query: {
          uid: uid,
        } })
    },
    goOtherFile(){
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      let uid = Cookies.get('PID')
      this.$router.push({ name: 'UserPhotoTreat',
      // this.$router.push({ name: 'UserPhotoTreat',
        query: {
          uid: uid,
        } })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getDataList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>

<style scoped>

</style>